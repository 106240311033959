import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { PortalModule } from './portal/portal.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';


@NgModule({ declarations: [
  AppComponent,
],
bootstrap: [AppComponent],
imports: [RouterModule,
  BrowserModule,
  RouterModule,
  AppRoutingModule,
  ReactiveFormsModule,
  AuthModule,
  CommonModule,
  CoreModule,
  SharedModule,
  PortalModule,
],
providers: [
  provideHttpClient(withInterceptorsFromDi()),
  {
    provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
    useValue: {
      hideSingleSelectionIndicator: true,
      hideMultipleSelectionIndicator: true,
    },
  },
] })
export class AppModule {}